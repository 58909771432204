// localStorage.setItem("CC_APP_ID", "253241e3f8f75720")
// localStorage.setItem("CC_REGION", "us")
// localStorage.setItem("CC_AUTH_KEY", "6da4dc0373925162283971737bf74dce8836f16c")
// localStorage.setItem("CC_ENV", "staging")

import { CometChatCalls } from "@cometchat/calls-sdk-javascript";

localStorage.setItem("CC_APP_ID", "207216c316d34c01")
localStorage.setItem("CC_REGION", "eu")
localStorage.setItem("CC_AUTH_KEY", "3acf9e78611ff393784506e7ed82b8fe2550b008")
localStorage.setItem("CC_ENV", "prod")

const appId = localStorage.getItem("CC_APP_ID");
const region = localStorage.getItem("CC_REGION");
const authKey = localStorage.getItem("CC_AUTH_KEY");
export const appEnvironment = localStorage.getItem("CC_ENV") ?? "staging";
console.log("appEnvironment", appEnvironment)

export const AppConstants = {
  APP_ID: appId ?? "253241e3f8f75720",
  REGION: region ?? "US",
  AUTH_KEY: authKey ?? "6da4dc0373925162283971737bf74dce8836f16c",
};

if (!appId) {
  localStorage.setItem("CC_APP_ID", AppConstants.APP_ID);
}
if (!region) {
  localStorage.setItem("CC_REGION", AppConstants.REGION);
}
if (!authKey) {
  localStorage.setItem("CC_AUTH_KEY", AppConstants.AUTH_KEY);
}

(window as any).CometChatCalls = CometChatCalls;
